// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Check if the user is authenticated when the app loads
  useEffect(() => {
    axios.get('/users/authenticated', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setUser(response.data.user);
        }
      })
      .catch(error => {
        console.error('Error checking authentication:', error);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
