// src/components/Navbar.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

function Navbar() {
  const { user, setUser } = useContext(AuthContext);

  const handleLogout = () => {
    axios.get('/users/logout', { withCredentials: true })
      .then(() => {
        setUser(null);
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <nav style={navStyle}>
      <Link to="/" style={linkStyle}>All Courses</Link>
      {user ? (
        <>
          <Link to="/my-reviews" style={linkStyle}>My Reviews</Link>
          <button onClick={handleLogout} style={buttonStyle}>Logout</button>
        </>
      ) : (
        <>
          <Link to="/login" style={linkStyle}>Login</Link>
          <Link to="/register" style={linkStyle}>Register</Link>
        </>
      )}
    </nav>
  );
}

const navStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '10px',
  borderBottom: '1px solid #000',
};

const linkStyle = {
  marginRight: '20px',
};

const buttonStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: 'inherit',
};

export default Navbar;