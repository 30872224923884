// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import MyReviewsPage from "./pages/MyReviewsPage";
import AddReviewPage from "./pages/AddReviewPage";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/my-reviews" element={<MyReviewsPage />} />
          <Route path="/add-review" element={<AddReviewPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
