// src/pages/HomePage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function HomePage() {
  const [courses, setCourses] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('overallRating'); // Default sort by overallRating
  const [expandedCourses, setExpandedCourses] = useState({});
  const [loadingReviews, setLoadingReviews] = useState({});

  useEffect(() => {
    fetchCourses();
  }, [sortCriteria]);

  const fetchCourses = () => {
    axios.get('/courses')
      .then(response => {
        let sortedCourses = response.data.sort((a, b) => b[sortCriteria] - a[sortCriteria]);
        setCourses(sortedCourses);
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const toggleReviews = (courseId) => {
    if (expandedCourses[courseId]) {
      // Hide the reviews
      setExpandedCourses(prev => ({ ...prev, [courseId]: false }));
    } else {
      // Show loading indicator
      setLoadingReviews(prev => ({ ...prev, [courseId]: true }));
      axios.get(`/reviews/course/${courseId}`)
        .then(response => {
          setCourses(prevCourses => prevCourses.map(course => {
            if (course._id === courseId) {
              return { ...course, reviews: response.data };
            }
            return course;
          }));
          setExpandedCourses(prev => ({ ...prev, [courseId]: true }));
        })
        .catch(error => {
          console.error('Error fetching reviews:', error);
        })
        .finally(() => {
          setLoadingReviews(prev => ({ ...prev, [courseId]: false }));
        });
    }
  };

  return (
    <div style={containerStyle}>
      <h1>All Courses</h1>
      <div style={sortContainerStyle}>
        <label htmlFor="sort">Sort by: </label>
        <select id="sort" onChange={handleSortChange} value={sortCriteria}>
          <option value="overallRating">Overall Rating</option>
          <option value="difficultyLevel">Difficulty Level</option>
          <option value="workload">Workload</option>
          <option value="teachingQuality">Teaching Quality</option>
        </select>
      </div>
      <ul style={listStyle}>
        {courses.map(course => (
          <li key={course._id} style={courseItemStyle}>
            <h2>{course.code}: {course.name}</h2>
            <p>{course.description}</p>
            <p>Overall Rating: {course.overallRating.toFixed(2)}</p>
            <p>Difficulty Level: {course.difficultyLevel.toFixed(2)}</p>
            <p>Workload: {course.workload.toFixed(2)}</p>
            <p>Teaching Quality: {course.teachingQuality.toFixed(2)}</p>
            <button onClick={() => toggleReviews(course._id)} style={buttonStyle}>
              {expandedCourses[course._id] ? 'Hide Reviews' : 'Show Reviews'}
            </button>
            {expandedCourses[course._id] && (
              <div style={reviewsContainerStyle}>
                {loadingReviews[course._id] ? (
                  <p>Loading reviews...</p>
                ) : course.reviews && course.reviews.length > 0 ? (
                  <ul style={reviewListStyle}>
                    {course.reviews.map(review => (
                      <li key={review._id} style={reviewItemStyle}>
                        <p>{review.text}</p>
                        <p style={reviewDateStyle}>{new Date(review.date).toLocaleDateString()}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No reviews yet.</p>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

const containerStyle = {
  padding: '20px',
};

const sortContainerStyle = {
  marginBottom: '20px',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

const courseItemStyle = {
  borderBottom: '1px solid #000',
  paddingBottom: '10px',
  marginBottom: '10px',
};

const buttonStyle = {
  marginTop: '10px',
  padding: '8px 16px',
};

const reviewsContainerStyle = {
  marginTop: '10px',
};

const reviewListStyle = {
  listStyleType: 'none',
  paddingLeft: 0,
};

const reviewItemStyle = {
  borderTop: '1px solid #ccc',
  paddingTop: '10px',
  marginTop: '10px',
};

const reviewDateStyle = {
  fontSize: '0.8em',
  color: '#555',
};

export default HomePage;