// src/pages/MyReviewsPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function MyReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchMyReviews();
    }
  }, [user, navigate]);

  const fetchMyReviews = () => {
    axios.get('/users/my-reviews', { withCredentials: true })
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Error fetching reviews:', error);
      });
  };

  return (
    <div style={containerStyle}>
      <h1>My Reviews</h1>
      <Link to="/add-review" style={buttonStyle}>Add New Review</Link>
      <ul style={listStyle}>
        {reviews.map(review => (
          <li key={review._id} style={reviewItemStyle}>
            <h2>{review.courseCode}: {review.courseName}</h2>
            <p>{review.text}</p>
            <p>Overall Rating: {review.overallRating}</p>
            {/* Display other ratings if desired */}
          </li>
        ))}
      </ul>
    </div>
  );
}

const containerStyle = {
  padding: '20px',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

const reviewItemStyle = {
  borderBottom: '1px solid #000',
  paddingBottom: '10px',
  marginBottom: '10px',
};

const buttonStyle = {
  display: 'inline-block',
  marginBottom: '20px',
};

export default MyReviewsPage;
