// src/pages/AddReviewPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function AddReviewPage() {
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [overallRating, setOverallRating] = useState(1);
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  const [workload, setWorkload] = useState(1);
  const [teachingQuality, setTeachingQuality] = useState(1);
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchCourses();
    }
  }, [user, navigate]);

  const fetchCourses = () => {
    axios.get('/courses')
      .then(response => {
        setCourses(response.data);
        if (response.data.length > 0) {
          setCourseId(response.data[0]._id);
        }
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/reviews', {
      courseId,
      overallRating,
      difficultyLevel,
      workload,
      teachingQuality,
      text,
    }, { withCredentials: true })
      .then(() => {
        navigate('/my-reviews');
      })
      .catch(error => {
        console.error('Error submitting review:', error);
        setError(error.response.data.message || 'Failed to submit review');
      });
  };

  return (
    <div style={containerStyle}>
      <h1>Add Review</h1>
      {error && <p style={errorStyle}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Course:</label><br />
          <select value={courseId} onChange={e => setCourseId(e.target.value)} style={inputStyle}>
            {courses.map(course => (
              <option key={course._id} value={course._id}>
                {course.code}: {course.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Overall Rating:</label><br />
          <input type="number" min="1" max="5" value={overallRating} onChange={e => setOverallRating(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Difficulty Level:</label><br />
          <input type="number" min="1" max="5" value={difficultyLevel} onChange={e => setDifficultyLevel(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Workload:</label><br />
          <input type="number" min="1" max="5" value={workload} onChange={e => setWorkload(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Teaching Quality:</label><br />
          <input type="number" min="1" max="5" value={teachingQuality} onChange={e => setTeachingQuality(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Review Text:</label><br />
          <textarea value={text} onChange={e => setText(e.target.value)} required style={textareaStyle}></textarea>
        </div>
        <button type="submit" style={buttonStyle}>Submit Review</button>
      </form>
    </div>
  );
}

const containerStyle = {
  padding: '20px',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  marginBottom: '10px',
};

const textareaStyle = {
  width: '100%',
  height: '100px',
  padding: '8px',
  marginBottom: '10px',
};

const buttonStyle = {
  padding: '10px 20px',
};

const errorStyle = {
  color: 'red',
};

export default AddReviewPage;
