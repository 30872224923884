// src/pages/LoginPage.js

import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post("/users/login", { email, password }, { withCredentials: true })
      .then((response) => {
        setUser(response.data.user);
        navigate("/my-reviews");
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        setError(error.response.data.message || "Login failed");
      });
  };

  return (
    <div style={containerStyle}>
      <h1>Login</h1>
      {error && <p style={errorStyle}>{error}</p>}
      <form onSubmit={handleLogin}>
        <div>
          <label>Email:</label>
          <br />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <div>
          <label>Password:</label>
          <br />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={inputStyle}
          />
        </div>
        <button type="submit" style={buttonStyle}>
          Login
        </button>
      </form>
    </div>
  );
}

const containerStyle = {
  padding: "20px",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  marginBottom: "10px",
};

const buttonStyle = {
  padding: "10px 20px",
};

const errorStyle = {
  color: "red",
};

export default LoginPage;
