// src/pages/RegisterPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [academicProgram, setAcademicProgram] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    axios.post('/users/register', { email, password, academicProgram })
      .then(() => {
        navigate('/login');
      })
      .catch(error => {
        console.error('Error registering:', error);
        setError(error.response.data.message || 'Registration failed');
      });
  };

  return (
    <div style={containerStyle}>
      <h1>Register</h1>
      {error && <p style={errorStyle}>{error}</p>}
      <form onSubmit={handleRegister}>
        <div>
          <label>Email:</label><br />
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Password:</label><br />
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} required style={inputStyle} />
        </div>
        <div>
          <label>Academic Program:</label><br />
          <input type="text" value={academicProgram} onChange={e => setAcademicProgram(e.target.value)} required style={inputStyle} />
        </div>
        <button type="submit" style={buttonStyle}>Register</button>
      </form>
    </div>
  );
}

const containerStyle = {
  padding: '20px',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  marginBottom: '10px',
};

const buttonStyle = {
  padding: '10px 20px',
};

const errorStyle = {
  color: 'red',
};

export default RegisterPage;
